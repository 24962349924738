import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fillRule="evenodd" d="M16 29.002c-.2 0-.3 0-.5-.2-13-9.8-14-13.4-14-17.6s3.4-8.2 7.6-8.2c2.9 0 5.6 1.6 6.9 4.2 1.3-2.6 3.9-4.3 6.9-4.2 4.2 0 7.6 3.7 7.6 8.2s-1 7.8-14 17.6c-.1 0-.3.2-.5.2m-6.9-24.5c-3.4 0-6.1 3-6.1 6.7s.9 6.9 12.9 16c12-9.1 13-12.6 13-16s-2.7-6.7-6.1-6.7c-2.9 0-5.4 2-6.1 4.9 0 .3-.4.6-.7.6-.4 0-.7-.2-.8-.6 0-.2-1.3-4.9-6.2-4.9z" data-name="Laag_1" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;