import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCart32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="m5.327 29-.26-.016a1.969 1.969 0 0 1-1.709-2.04l1-22.064A1.97 1.97 0 0 1 6.327 3h19.205c1.048 0 1.912.82 1.966 1.866l1.145 22.128-.006.207A1.969 1.969 0 0 1 26.675 29zm.581-24.05-1 22.063a.418.418 0 0 0 .398.437h21.371a.418.418 0 0 0 .421-.415L25.95 4.95a.416.416 0 0 0-.418-.394V4.55H6.325a.418.418 0 0 0-.417.4m10.107 9.386c-2.849 0-5.733-2.236-5.733-6.507v-.054a.715.715 0 1 1 1.43.054c0 3.487 2.228 5.074 4.3 5.074a4.045 4.045 0 0 0 2.926-1.229 5.375 5.375 0 0 0 1.361-3.844v-.054a.716.716 0 0 1 1.431.054 6.775 6.775 0 0 1-1.764 4.843 5.479 5.479 0 0 1-3.931 1.663h-.021Z" /></SvgIcon>;
};
const Memo = memo(SvgCart32);
export default Memo;