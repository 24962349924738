import type { InstallmentCalculatorConfig } from '../../default/product/installmentCalculator.types';
import type { DeepPartial } from '../../default/types';

export const installmentCalculatorConfig: DeepPartial<InstallmentCalculatorConfig> = {
  hasLegalHints: false,
  legalHintsImage: {
    src: 'https://assets.otto.nl/i/empiriecom/kreditwarnung_nl_balk_afm2',
    width: 1768,
    height: 122,
  },
  InstallmentCalculatorCallToActionLayout: null,
  hasSpecialCreditColors: true,
};
