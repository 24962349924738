import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M28.267 28.997a.2.2 0 0 1-.2-.2 11.08 11.08 0 0 0-3-7.43 15.239 15.239 0 0 0-2.234-2.076l-.271-.2-.119-.083a11.588 11.588 0 0 1-6.409 2.055 11.295 11.295 0 0 1-4.945-1.188 11.525 11.525 0 0 1-1.4-.8l-.1-.072a11.397 11.397 0 0 0-3.822 3.807 12.767 12.767 0 0 0-1.616 4.144c-.092.45-.159.904-.2 1.361l-.019.252-.009.239a.2.2 0 0 1-.2.189H2.667a.203.203 0 0 1-.2-.198v-.085l.01-.219v-.006l.023-.3c.046-.513.122-1.023.226-1.528a14.271 14.271 0 0 1 1.8-4.615 12.888 12.888 0 0 1 4.758-4.554l.32-.18a.2.2 0 0 1 .223.021l.327.273.145.109.217.151c.388.26.794.492 1.215.695a9.848 9.848 0 0 0 4.306 1.04 10.355 10.355 0 0 0 5.985-2.069l.284-.2a.2.2 0 0 1 .214-.008l.3.177c.076.045.187.117.308.2l.175.122c.191.133.387.278.584.431a16.737 16.737 0 0 1 2.286 2.151 12.5 12.5 0 0 1 3.361 8.4.2.2 0 0 1-.2.2s-1.067-.006-1.067-.006M8.334 10.663a7.674 7.674 0 0 1 7.667-7.666 7.674 7.674 0 0 1 7.665 7.665 7.674 7.674 0 0 1-7.665 7.665 7.674 7.674 0 0 1-7.665-7.665h-.002Zm1.461 0a6.213 6.213 0 0 0 6.2 6.2 6.213 6.213 0 0 0 6.2-6.2 6.213 6.213 0 0 0-6.2-6.2 6.212 6.212 0 0 0-6.198 6.199h-.002Z" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;