import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fillRule="evenodd" d="m28.96 27.795-7.7-7.8c3.7-4.4 3.2-11-1.2-14.7-4.4-3.7-11-3.2-14.7 1.2-3.7 4.4-3.2 11 1.2 14.7 3.9 3.3 9.7 3.3 13.6 0l7.7 7.8c.3.3.8.2 1.1-.1.2-.3.2-.7 0-1zm-24.5-14.5c0-4.9 3.9-8.8 8.8-8.8s8.8 3.9 8.8 8.8-3.9 8.8-8.8 8.8-8.8-3.9-8.8-8.8" data-name="Laag_1" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;