import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fillRule="evenodd" d="M14.382 3.638c.918-.785 2.364-.786 3.286.005l3.904 3.292h4.142c.357 0 .646.29.647.647l-.002 5.117.999.847a.634.634 0 0 1 .301.532v14.225a.648.648 0 0 1-.647.647H4.995a.647.647 0 0 1-.648-.648V14.078c0-.01-.005-.017-.005-.027a.647.647 0 0 1 .052-.212l.004-.017a.642.642 0 0 1 .104-.154c.016-.017.026-.039.043-.055l.017-.023 1.07-.934V7.584a.648.648 0 0 1 .647-.647h4.166L14.38 3.64zm4.321 18.669-.88.793a2.807 2.807 0 0 1-1.826.679c-.786 0-1.394-.373-1.885-.737l-.797-.699-6.603 5.312h18.532zm7.659-6.847a900.94 900.94 0 0 0-6.687 5.97l6.687 5.466zm-20.721.095v11.297l6.687-5.377a972.696 972.696 0 0 1-6.687-5.919zm19.426-7.326H6.928v6.741a1372.46 1372.46 0 0 0 7.998 7.064c.349.256.688.449 1.072.449.356 0 .7-.126.971-.356.168-.152 2.243-2.024 4.429-3.982l.789-.706c1.05-.939 2.075-1.852 2.878-2.56l.003-6.65zm-4.537 6.475a.648.648 0 1 1 0 1.296h-9.056a.648.648 0 1 1 0-1.296zm-.011-3.894a.648.648 0 1 1 0 1.296h-9.055a.648.648 0 1 1 0-1.296zm-3.691-6.182c-.432-.37-1.183-.369-1.61-.003l-2.755 2.309h7.1z" clipRule="evenodd" /></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;