import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fillRule="evenodd" d="M27.55 3.05c.4 0 .7.3.7.7v20.2c0 .4-.3.7-.7.7h-12.8l-5.6 4.2c-.1 0-.3.1-.4.1-.4 0-.7-.3-.7-.7v-3.6h-3.6c-.4 0-.7-.3-.7-.7V3.75c0-.4.3-.7.7-.7zm-.8 1.4H5.15v18.8h3.6c.4 0 .7.3.7.7v2.9l4.6-3.5c.1 0 .3-.1.4-.1h12.3zm-10.8 7.3c.4 0 .7.3.7.7v7.2c0 .4-.3.7-.7.7s-.7-.3-.7-.7v-7.2c0-.4.3-.7.7-.7m0-4c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1-1.1-.5-1.1-1.1.5-1.1 1.1-1.1" data-name="Laag_1" /></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;